import React, { useState, useContext, useCallback } from 'react';
import UserContext from '../../context/UserContext';
import TermsAndConditions from '../HomeComponents/Legal/TermsAndConditions';
import './addBalance.css';
import { useTheme } from '../darkMode/ThemeProvider';
import { toast } from 'react-toastify';
import { PAYMENT_PROVIDERS, PAYMENT_FEES } from '../utils/constants';

const AddBalance = ({ onClose }) => {
  const { user, authToken } = useContext(UserContext);
  const { theme } = useTheme();
  const [baseAmount, setBaseAmount] = useState('');
  const [error, setError] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // Calcular comisiones y total (solo para informar al usuario)
  const numericBaseAmount = parseInt(baseAmount, 10) || 0;
  const platformFee = numericBaseAmount * PAYMENT_FEES.PLATFORM; // 5%
  const mercadoPagoFee = (numericBaseAmount + platformFee) * PAYMENT_FEES.MERCADOPAGO; // 12.7%
  const totalAmount = numericBaseAmount + platformFee + mercadoPagoFee;

  const handleSubmit = useCallback(async () => {
    setError('');
    setLoadingRequest(true);

    try {
      // Validaciones
      if (!numericBaseAmount || numericBaseAmount <= 0) {
        throw new Error('Por favor, ingrese un monto válido.');
      }
      if (!acceptedTerms) {
        throw new Error('Debes aceptar los términos y condiciones.');
      }
      if (!authToken) {
        throw new Error('No se pudo obtener el token de autenticación.');
      }

      // Aquí usamos /payments en lugar de /api/payments
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payments/create-balance-payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            // Enviamos principalmente 'amount'.
            // Si quieres enviar info extra, no hay problema, 
            // pero tu backend recalculará las comisiones igualmente.
            amount: numericBaseAmount,
            platformFee,
            providerFee: mercadoPagoFee,
            totalAmount,
            provider: PAYMENT_PROVIDERS.MERCADO_PAGO
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error al crear el pago.');
      }

      // Redireccionamos a la URL de MercadoPago
      if (data.init_point) {
        window.location.href = data.init_point;
      } else {
        throw new Error('No se recibió la URL de pago de MercadoPago.');
      }

    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoadingRequest(false);
    }
  }, [
    numericBaseAmount,
    authToken,
    acceptedTerms,
    platformFee,
    mercadoPagoFee,
    totalAmount
  ]);

  return (
    <div className={`modal-addBalance-overlay ${theme === 'dark' ? 'dark' : ''}`}>
      <div className="modal-addBalance-content">
        <div className="modal-addBalance-header">
          <h3>Cargar Saldo con Mercado Pago</h3>
        </div>
        <div className="modal-addBalance-body">
          {error && (
            <div className="modal-addBalance-alert-danger">
              {error}
            </div>
          )}

          <div className="modal-addBalance-amount-input">
            <label>Monto a cargar</label>
            <input
              type="text"
              placeholder="$0.00"
              value={baseAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                setBaseAmount(value);
              }}
              className="modal-addBalance-input"
              inputMode="numeric"
            />
          </div>

          {numericBaseAmount > 0 && (
            <div className="modal-addBalance-summary">
              <div className="summary-item">
                <span>Monto base:</span>
                <span>${numericBaseAmount.toFixed(2)}</span>
              </div>
              <div className="summary-item fee">
                <span>Comisión de plataforma (5%):</span>
                <span>${platformFee.toFixed(2)}</span>
              </div>
              <div className="summary-item fee">
                <span>Comisión de Mercado Pago (12.7%):</span>
                <span>${mercadoPagoFee.toFixed(2)}</span>
              </div>
              <div className="summary-item total">
                <span>Total a pagar:</span>
                <span>${totalAmount.toFixed(2)}</span>
              </div>
            </div>
          )}

          <div className="modal-addBalance-terms">
            <input
              type="checkbox"
              id="terms"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
            />
            <label htmlFor="terms">
              He leído y acepto los términos y condiciones
            </label>
          </div>
        </div>
        <div className="modal-addBalance-footer">
          <button
            onClick={onClose}
            className="modal-addBalance-button secondary"
            disabled={loadingRequest}
          >
            Cancelar
          </button>
          <button
            onClick={handleSubmit}
            disabled={loadingRequest || !baseAmount || !acceptedTerms}
            className="modal-addBalance-button primary"
          >
            {loadingRequest ? (
              <>
                <span className="modal-addBalance-loader"></span>
                Procesando...
              </>
            ) : (
              'Pagar con Mercado Pago'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBalance;
