// src/components/Layout.js

import React, { useState } from 'react';
import Header from './Header/Header';
import AddBalance from '../components/Wallet/AddBalance';
import '../Styles/Layout.css';
import Notifications from './Header/Notifications/Notifications';

const Layout = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const openNotifications = () => {
    setNotificationsVisible(true);
  };

  const closeNotifications = () => {
    setNotificationsVisible(false);
  };

  return (
    <div className="app-container">
      <Header 
        openModal={openModal} 
        openNotifications={openNotifications}
      />
      <main className={`main-content ${activeModal ? 'blur-background' : ''}`}>
        {children}
      </main>

      {activeModal === 'addBalance' && (
        <AddBalance onClose={closeModal} />
      )}
      
      {notificationsVisible && (
        <Notifications onClose={closeNotifications} />
      )}
    </div>
  );
};

export default Layout;
