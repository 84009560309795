import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FaPlus,
  FaUser,
  FaBriefcase,
  FaCompass,
  FaCog,
  FaMoneyBillWave,
  FaCrown,
  FaSignOutAlt,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import styles from './DropdownMenu.module.css';
import { useMembership } from '../../../hooks/useMembership';

const DropdownMenu = ({
  user,
  balance = 0,
  pendingBalance = 0,
  onAddBalance,
  onLogout,
  setDropdownVisible,
}) => {
  const navigate = useNavigate();
  const { isActive } = useMembership();
  const formattedBalance = Number(balance).toFixed(2);
  const formattedPendingBalance = Number(pendingBalance).toFixed(2);

  const handleItemClick = (callback) => {
    if (callback) callback();
    setDropdownVisible(false);
  };

  const menuItems = [
    { 
      to: `/profile/${user?.uid}`, 
      icon: <FaUser />, 
      text: 'Perfil' 
    },
    { 
      to: '/workbench', 
      icon: <FaBriefcase />, 
      text: 'Mesa de Trabajo' 
    },
    {
      to: '/dashboard',
      icon: <FaCompass />,
      text: 'Dashboard',
    },
    { 
      to: '/configuration', 
      icon: <FaCog />, 
      text: 'Configuración' 
    },
    { 
      to: '/monetization',
      icon: <FaMoneyBillWave />,
      text: 'Monetización',
      restricted: !isActive,
      restrictedMessage: 'Requiere membresía Premium',
    },
  ];

  return (
    <motion.div 
      className={styles.dropdown}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
    >
      {/* Balance Section */}
      <button
        className={`${styles.dropdownItem} ${styles.balance}`}
        onClick={onAddBalance} // Usar la función pasada desde Header
      >
        <span>Balance: ${formattedBalance}</span>
        <FaPlus className={styles.dropdownIcon} />
      </button>
      
      <div className={`${styles.dropdownItem} ${styles.pending}`}>
        <span>Pendiente: ${formattedPendingBalance}</span>
      </div>

      {/* Menu Items */}
      {menuItems.map((item, index) => (
        <Link
          key={index}
          to={item.to}
          className={`${styles.dropdownItem} ${item.restricted ? styles.restricted : ''}`}
          onClick={() => handleItemClick()}
        >
          <span className={styles.dropdownIcon}>{item.icon}</span>
          <span>{item.text}</span>
          {item.restricted && (
            <span className={styles.restrictedBadge}>
              {item.restrictedMessage}
            </span>
          )}
        </Link>
      ))}

      {/* Premium/Membership Button */}
      <Link
        to="/suscripcion"
        className={`${styles.dropdownItem} ${isActive ? styles.premiumItem : ''}`}
        onClick={() => handleItemClick()}
      >
        <FaCrown className={`${styles.dropdownIcon} ${isActive ? styles.premiumIcon : ''}`} />
        <span>{isActive ? 'Membresía Premium Activa' : 'Obtener Premium'}</span>
        {isActive && (
          <span className={styles.premiumTag}>Premium</span>
        )}
      </Link>

      {/* Logout Button */}
      <button
        onClick={() => handleItemClick(onLogout)}
        className={`${styles.dropdownItem} ${styles.logoutButton}`}
      >
        <FaSignOutAlt className={styles.dropdownIcon} />
        <span>Cerrar sesión</span>
      </button>
    </motion.div>
  );
};

DropdownMenu.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string,
    role: PropTypes.string,
  }),
  balance: PropTypes.number,
  pendingBalance: PropTypes.number,
  onAddBalance: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  setDropdownVisible: PropTypes.func.isRequired,
};

export default DropdownMenu;