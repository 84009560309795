// src/components/Terms/TermsAndConditions.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import styles from './TermsAndConditions.module.css';

const TermsAndConditions = () => {
    return (
        <div className={styles.termsContainer}>
            <Link to="/" className={styles.backButton}>
                <FaArrowLeft /> Volver al inicio
            </Link>

            <div className={styles.termsContent}>
                <header className={styles.termsHeader}>
                    <h1>Términos y Condiciones</h1>
                    <p className={styles.lastUpdated}>Última actualización: Noviembre 2024</p>
                </header>

                <section className={styles.termsSection}>
                    <h2>1. Introducción</h2>
                    <p>Al utilizar Illustra, aceptas estos términos y condiciones en su totalidad. Por favor, léelos cuidadosamente antes de realizar cualquier transacción.</p>
                </section>

                <section className={styles.termsSection}>
                    <h2>2. Servicios de Ilustración</h2>
                    
                    <h3>2.1 Proceso de Contratación</h3>
                    <ul>
                        <li>Cada contratación genera un acuerdo vinculante entre cliente e ilustrador</li>
                        <li>Los pagos se retienen en garantía hasta la aprobación final</li>
                        <li>Se incluyen 2 rondas de revisiones en el precio base</li>
                        <li>Tiempos de entrega según lo especificado en cada servicio</li>
                    </ul>

                    <h3>2.2 Derechos y Licencias</h3>
                    <ul>
                        <li>Los derechos de uso se transfieren al completar el pago</li>
                        <li>El ilustrador mantiene derechos de portfolio</li>
                        <li>Usos comerciales requieren acuerdo específico</li>
                        <li>No se permite la reventa de ilustraciones</li>
                    </ul>
                </section>

                <section className={styles.termsSection}>
                    <h2>3. Pagos y Tarifas</h2>
                    
                    <h3>3.1 Estructura de Precios</h3>
                    <ul>
                        <li>Comisión por recarga: ~17% (varía según método de pago)</li>
                        <li>Comisión de retiro: 10%</li>
                        <li>Pagos retenidos en garantía</li>
                        <li>Liberación tras aprobación del cliente</li>
                    </ul>

                    <h3>3.2 Política de Reembolsos</h3>
                    <div className={styles.refundTable}>
                        <div className={styles.tableHeader}>
                            <span>Etapa</span>
                            <span>Reembolso</span>
                        </div>
                        <div className={styles.tableRow}>
                            <span>Antes de comenzar</span>
                            <span>90%</span>
                        </div>
                        <div className={styles.tableRow}>
                            <span>Durante bocetos</span>
                            <span>70%</span>
                        </div>
                        <div className={styles.tableRow}>
                            <span>Trabajo en progreso</span>
                            <span>50%</span>
                        </div>
                        <div className={styles.tableRow}>
                            <span>Trabajo finalizado</span>
                            <span>0%</span>
                        </div>
                    </div>
                </section>

                <section className={styles.termsSection}>
                    <h2>4. Resolución de Conflictos</h2>
                    
                    <h3>4.1 Proceso de Disputa</h3>
                    <ol>
                        <li>Reporte inicial dentro de las 24h</li>
                        <li>Mediación por Illustra (5 días hábiles)</li>
                        <li>Resolución final vinculante</li>
                        <li>Posible apelación en casos específicos</li>
                    </ol>

                    <h3>4.2 Motivos de Disputa</h3>
                    <ul>
                        <li>Incumplimiento de plazos acordados</li>
                        <li>Calidad inferior a la mostrada</li>
                        <li>Cambios no autorizados</li>
                        <li>Falta de comunicación</li>
                    </ul>
                </section>

                <section className={styles.termsSection}>
                    <h2>5. Derechos y Garantías</h2>
                    
                    <h3>5.1 Garantías del Ilustrador</h3>
                    <ul>
                        <li>Trabajo 100% original y propio</li>
                        <li>Cumplimiento de plazos acordados</li>
                        <li>Calidad acorde al portfolio</li>
                        <li>Confidencialidad del proyecto</li>
                    </ul>

                    <h3>5.2 Garantías del Cliente</h3>
                    <ul>
                        <li>Pago asegurado en plataforma</li>
                        <li>Derechos sobre referencias proporcionadas</li>
                        <li>Uso legal del contenido</li>
                        <li>Comunicación oportuna</li>
                    </ul>
                </section>

                <section className={styles.termsSection}>
                    <h2>6. Privacidad y Datos</h2>
                    <p>Nos comprometemos a proteger tu información personal según nuestra política de privacidad. Los datos serán utilizados únicamente para facilitar los servicios de la plataforma.</p>
                </section>

                <section className={styles.termsSection}>
                    <h2>7. Cambios en los Términos</h2>
                    <p>Illustra se reserva el derecho de modificar estos términos. Los cambios serán notificados y el uso continuado implica su aceptación.</p>
                </section>

                <footer className={styles.termsFooter}>
                    <p>Para consultas sobre estos términos:</p>
                    <a href="mailto:illustra.contacto@gmail.com">illustra.contacto@gmail.com</a>
                </footer>
            </div>
        </div>
    );
};

export default TermsAndConditions;