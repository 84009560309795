import React, { useState, useContext, useCallback } from 'react';
import UserContext from '../../context/UserContext';
import { toast } from 'react-toastify';
import './AddBalance.module.css';
import { useTheme } from '../darkMode/ThemeProvider';

// Constantes para las comisiones
const FEES = {
  PLATFORM: 0.05,  // 5%
  MERCADOPAGO: 0.127  // 12.7%
};

const MIN_AMOUNT = 5;  // Monto mínimo $5 (en dólares/pesos)
const MAX_AMOUNT = 1000;  // Monto máximo $1000

const AddBalance = ({ onClose }) => {
  const { user, authToken } = useContext(UserContext);
  const { theme } = useTheme();
  const [baseAmount, setBaseAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // Cálculos de comisiones y total
  const numericBaseAmount = parseFloat(baseAmount) || 0;
  const platformFee = numericBaseAmount * FEES.PLATFORM;
  const mercadoPagoFee = (numericBaseAmount + platformFee) * FEES.MERCADOPAGO;
  const totalAmount = numericBaseAmount + platformFee + mercadoPagoFee;

  const validateAmount = useCallback(() => {
    if (numericBaseAmount < MIN_AMOUNT) {
      toast.error(`El monto mínimo es $${MIN_AMOUNT}`);
      return false;
    }
    if (numericBaseAmount > MAX_AMOUNT) {
      toast.error(`El monto máximo es $${MAX_AMOUNT}`);
      return false;
    }
    return true;
  }, [numericBaseAmount]);

  const handleSubmit = useCallback(async () => {
    if (!validateAmount() || !acceptedTerms) {
      return;
    }

    setLoading(true);

    try {
      // Convertir a centavos para la API
      const amountInCents = Math.round(numericBaseAmount * 100);
      const platformFeeInCents = Math.round(platformFee * 100);
      const providerFeeInCents = Math.round(mercadoPagoFee * 100);
      const totalAmountInCents = Math.round(totalAmount * 100);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payments/create-payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: user.uid,
            baseAmount: amountInCents,
            platformFee: platformFeeInCents,
            providerFee: providerFeeInCents,
            totalAmount: totalAmountInCents,
            provider: 'MERCADOPAGO'
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Error al crear el pago');
      }

      const data = await response.json();

      await fetch(`${process.env.REACT_APP_API_URL}/transactions/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId: user.uid,
          amount: totalAmountInCents,
          status: 'pending',
          preferenceId: data.preference_id,
          timestamp: new Date()
        }),
      });

      window.location.href = data.init_point;
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al procesar el pago. Por favor intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  }, [numericBaseAmount, authToken, user, acceptedTerms, platformFee, mercadoPagoFee, totalAmount, validateAmount]);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    
    // Validar que solo haya un punto decimal
    if ((value.match(/\./g) || []).length <= 1) {
      setBaseAmount(value);
    }
  };

  return (
    <div className={`modal-overlay ${theme}`}>
      <div className="modal-content">
        <div className="modal-header">
          <h2>Cargar Saldo</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="modal-body">
          <div className="input-group">
            <label htmlFor="amount">Monto a cargar (mínimo $5)</label>
            <div className="amount-input-container">
              <span className="currency-symbol">$</span>
              <input
                id="amount"
                type="text"
                value={baseAmount}
                onChange={handleAmountChange}
                placeholder="5.00"
                disabled={loading}
                inputMode="numeric"
              />
            </div>
          </div>

          {numericBaseAmount > 0 && (
            <div className="fee-breakdown">
              <div className="fee-item">
                <span>Monto base:</span>
                <span>${numericBaseAmount.toFixed(2)}</span>
              </div>
              <div className="fee-item">
                <span>Comisión plataforma ({FEES.PLATFORM * 100}%):</span>
                <span>${platformFee.toFixed(2)}</span>
              </div>
              <div className="fee-item">
                <span>Comisión Mercado Pago ({FEES.MERCADOPAGO * 100}%):</span>
                <span>${mercadoPagoFee.toFixed(2)}</span>
              </div>
              <div className="fee-item total">
                <span>Total a pagar:</span>
                <span>${totalAmount.toFixed(2)}</span>
              </div>
            </div>
          )}

          <div className="terms-container">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                disabled={loading}
              />
              Acepto los términos y condiciones
            </label>
          </div>
        </div>

        <div className="modal-footer">
          <button 
            className="cancel-button"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </button>
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={loading || !acceptedTerms || numericBaseAmount < MIN_AMOUNT}
          >
            {loading ? (
              <>
                <span className="loader"></span>
                Procesando...
              </>
            ) : (
              'Continuar con Mercado Pago'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBalance;