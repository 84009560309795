// src/components/Header/Header.js

import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaBell, FaBars, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import UserContext from '../../context/UserContext';
import { logout, db } from '../../firebaseConfig';
import AddBalance from './addBalance'; // Renombrado de AddBalanceModal a AddBalance
import Membership from '../membership/Membership';
import SearchBar from './SearchBar/SearchBar';
import DropdownMenu from './UserMenu/DropdownMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import styles from './Header.module.css';
import { toast } from 'react-toastify';
import { useMembership } from '../../hooks/useMembership';
import MembershipBadge from '../membership/MembershipBadge';

import { doc, getDoc, updateDoc, collection, onSnapshot } from 'firebase/firestore';

const defaultProfilePic = 'https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/non_profile_pic.png?alt=media&token=9ef84cb8-bae5-48cf-aed9-f80311cc2886';

const Header = ({ openModal, openNotifications }) => {
  const { user } = useContext(UserContext);
  const { isPremium } = useMembership();
  const navigate = useNavigate();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [balance, setBalance] = useState(0);
  const [pendingBalance, setPendingBalance] = useState(0);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [profilePic, setProfilePic] = useState(defaultProfilePic);
  const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [animateBell, setAnimateBell] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const dropdownRef = useRef(null);
  const notificationsRef = useRef(null);
  const userButtonRef = useRef(null);

  const toggleDropdown = useCallback(() => {
    setDropdownVisible((prev) => !prev);
    setMobileMenuOpen(false);
  }, []);

  const handleToggleNotifications = useCallback(() => {
    openNotifications();
    setDropdownVisible(false);
    setMobileMenuOpen(false);
  }, [openNotifications]);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
    setDropdownVisible(false);
  }, []);

  const handleMembershipClick = useCallback(() => {
    setShowMembershipModal(true);
    setDropdownVisible(false);
    setMobileMenuOpen(false);
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/');
      toast.success('Has cerrado sesión exitosamente');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      toast.error('Error al cerrar sesión');
    }
  }, [navigate]);

  const handleAddBalance = useCallback(async (amount) => {
    if (!user) return;

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const newBalance = Number(userData.balance || 0) + amount;
        await updateDoc(userRef, { balance: newBalance });
        setBalance(newBalance);
        setShowAddBalanceModal(false);
        toast.success('Balance actualizado exitosamente');
      }
    } catch (error) {
      console.error('Error al añadir balance:', error);
      toast.error('Error al actualizar el balance');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setBalance(userData.balance || 0);
          setPendingBalance(userData.pendingBalance || 0);
          setProfilePic(userData.photoURL || defaultProfilePic);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const notificationsCollectionRef = collection(db, 'users', user.uid, 'Notifications');
      const unsubscribe = onSnapshot(notificationsCollectionRef, (snapshot) => {
        setHasNotifications(!snapshot.empty);
        if (!snapshot.empty) {
          setAnimateBell(true);
          setTimeout(() => setAnimateBell(false), 1000);
        }
      });
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !userButtonRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.headerTitle} onClick={() => navigate('/')}>
          ILLUSTRA
        </h1>

        <div className={styles.headerSearchBar}>
          <SearchBar />
        </div>

        <nav className={styles.headerNav}>
          <Link className={styles.headerButton} to="/explore-posts">
            Explorar
          </Link>
          <Link className={styles.headerButton} to="/explore-services">
            Servicios
          </Link>
          <Link className={styles.headerButton} to="/explore-users">
            Usuarios
          </Link>

          {user ? (
            <>
              <div className={styles.headerNotifications} ref={notificationsRef}>
                <button
                  className={styles.notificationBellButton}
                  onClick={handleToggleNotifications}
                  aria-label="Notificaciones"
                >
                  <div
                    className={`${styles.headerNotificationsIcon} ${
                      animateBell ? styles.notificationBounce : ''
                    }`}
                  >
                    <FaBell size={24} color={hasNotifications ? '#ff1493' : '#666'} />
                    {hasNotifications && (
                      <motion.span
                        className={styles.headerNotificationsBadge}
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 0.3 }}
                      />
                    )}
                  </div>
                </button>
              </div>

              <div
                className={styles.headerUser}
                onClick={toggleDropdown}
                ref={userButtonRef}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') toggleDropdown();
                }}
              >
                <img
                  src={profilePic}
                  alt="Profile"
                  className={styles.headerProfilePic}
                />
                <span className={styles.headerUsername}>
                  {user.username}
                  {isPremium && <MembershipBadge />}
                </span>

                <AnimatePresence>
                  {dropdownVisible && (
                    <motion.div
                      ref={dropdownRef}
                      className={styles.dropdownContainer}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                    >
                      <DropdownMenu
                        user={user}
                        balance={balance}
                        pendingBalance={pendingBalance}
                        onAddBalance={() => setShowAddBalanceModal(true)}
                        onLogout={handleLogout}
                        setDropdownVisible={setDropdownVisible}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </>
          ) : (
            <div className={styles.headerButtons}>
              <Link to="/login" className={styles.headerButton}>
                Iniciar Sesión
              </Link>
              <Link to="/register" className={styles.headerButton}>
                Registrarse
              </Link>
            </div>
          )}
        </nav>

        <button
          className={styles.mobileMenuButton}
          onClick={toggleMobileMenu}
          aria-label="Menú móvil"
        >
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      <AnimatePresence>
        {mobileMenuOpen && (
          <MobileMenu
            user={user}
            onClose={() => setMobileMenuOpen(false)}
            onMembershipClick={handleMembershipClick}
            onLogout={handleLogout}
          />
        )}
      </AnimatePresence>

      {showAddBalanceModal && (
        <AddBalance
          onClose={() => setShowAddBalanceModal(false)}
          onAddBalance={handleAddBalance}
        />
      )}

      {showMembershipModal && (
        <Membership onClose={() => setShowMembershipModal(false)} />
      )}
    </header>
  );
};

export default Header;
