// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, signOut, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDA69pFhhtib00x_FdWx-Y4_IVvrjYG_ag",
  authDomain: "illustra-6ca8a.firebaseapp.com",
  databaseURL: "https://illustra-6ca8a-default-rtdb.firebaseio.com",
  projectId: "illustra-6ca8a",
  storageBucket: "illustra-6ca8a.appspot.com",
  messagingSenderId: "643320223920",
  appId: "1:643320223920:web:d39c2493da8472ab934375",
  measurementId: "G-69S0CBVXMH"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Auth
export const auth = getAuth(app);

// Establecer persistencia de sesión
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

// Función para cerrar sesión
const logout = async () => {
  try {
    await signOut(auth);
    // Limpiar localStorage al cerrar sesión
    localStorage.removeItem('userData');
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

// Inicializar Firestore
export const db = getFirestore(app);

// Inicializar Storage
export const storage = getStorage(app);

// Inicializar Functions
const functions = getFunctions(app);

// Si estás en desarrollo, conecta con el emulador
if (process.env.NODE_ENV === 'development') {
  // Ajusta el puerto si tu emulador de Functions usa otro
  functions.functionsEmulatorHost = 'localhost';
  functions.functionsEmulatorPort = 5001;
}

export { functions, httpsCallable };
export { logout };
