// ==============================================
// ==================== MEMBERSHIP =============
// ==============================================

// Tipos de Membresía
export const MEMBERSHIP_TYPES = {
  FREE: 'free',
  PREMIUM: 'premium',
  ADMIN: 'admin',
};

// Características de Membresía
export const MEMBERSHIP_FEATURES = {
  free: {
    canMonetize: false,
    isVerified: false,
    priorityListing: false,
    maxPortfolioSize: 10,
    earlyAccess: false,
  },
  premium: {
    canMonetize: true,
    isVerified: true,
    priorityListing: true,
    maxPortfolioSize: 100,
    earlyAccess: true,
    canHaveSubscribers: true,
    canCreatePremiumContent: true,
    maxSubscribers: 1000,
    maxGalleries: 10,
    maxPremiumContent: 500,
    features: {
      customGallery: true,
      adultContent: true,
      subscriberSystem: true,
      prioritySupport: true
    }
  },
  admin: {
    canMonetize: true,
    isVerified: true,
    priorityListing: true,
    maxPortfolioSize: Infinity,
    earlyAccess: true,
    adminPrivileges: true,
    permanentAccess: true,
    canHaveSubscribers: true,
    canCreatePremiumContent: true,
    maxSubscribers: Infinity,
    maxGalleries: Infinity,
    maxPremiumContent: Infinity,
    features: {
      customGallery: true,
      adultContent: true,
      subscriberSystem: true,
      prioritySupport: true,
      adminPanel: true
    }
  }
};

// Permisos de Membresía
export const MEMBERSHIP_PERMISSIONS = {
  FREE: ['basic_access', 'view_content'],
  PREMIUM: ['basic_access', 'view_content', 'create_content', 'monetize', 'premium_features'],
  ADMIN: ['basic_access', 'view_content', 'create_content', 'monetize', 'premium_features', 'admin_features'],
};

// Precios de Membresía
export const MEMBERSHIP_PRICES = {
  premium: {
    MONTHLY: 5999,
    YEARLY: 59999,
  },
};

// ==============================================
// ==================== PAYMENT ================
// ==============================================

// Proveedores de Pago
export const PAYMENT_PROVIDERS = {
  MERCADO_PAGO: 'mercadopago',
  TWOCHECKOUT: 'twocheckout',
};

// Comisiones de Pago
export const PAYMENT_FEES = {
  PLATFORM: 0.05,
  MERCADOPAGO: 0.127,
  TWOCHECKOUT: 0.056,
};

export const COMMISSION_RATE = 0.1;

// Estados de Pago
export const PAYMENT_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  REFUNDED: 'refunded',
};

// Estados de Transacción
export const TRANSACTION_STATUS = {
  ...PAYMENT_STATUS,
  INITIATED: 'initiated',
  EXPIRED: 'expired',
};

// Etiquetas de Métodos de Pago
export const PAYMENT_METHODS_LABELS = {
  mercadopago: 'Mercado Pago',
  twocheckout: 'Tarjeta de Crédito/Débito',
};

// Tipos de Transacción
export const TRANSACTION_TYPES = {
  PAYMENT: 'payment',
  WITHDRAWAL: 'withdrawal',
  REFUND: 'refund',
  SUBSCRIPTION: 'subscription',
  MEMBERSHIP: 'membership',
};

// Endpoints de la API
export const API_ENDPOINTS = {
  CREATE_BALANCE_PAYMENT: '/payments/create-balance-payment',
  VERIFY_PAYMENT: '/payments/verify',
  PAYMENT_SUCCESS: '/payments/success',
  PAYMENT_FAILURE: '/payments/failure',
  PAYMENT_PENDING: '/payments/pending',
  MERCADOPAGO_WEBHOOK: '/payments/notifications',
};

// ==============================================
// ==================== SUBSCRIPTION ============ 
// ==============================================

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  PENDING: 'pending'
};

// Períodos de Suscripción
export const SUBSCRIPTION_PERIODS = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const SUBSCRIPTION_LIMITS = {
  MAX_SUBSCRIBER_PRICE: 999.99,
  MIN_SUBSCRIBER_PRICE: 4.99,
  MAX_GALLERIES: 10,
  MAX_IMAGES_PER_GALLERY: 100,
  MAX_PREMIUM_GALLERIES: 20
};

// ==============================================
// ==================== CONTENT ================
// ==============================================

export const CONTENT_ACCESS_LEVELS = {
  ALL: 'all',
  SUBSCRIBERS: 'subscribers',
  PREMIUM: 'premium'
};

export const CONTENT_TYPES = {
  IMAGE: 'image',
  GALLERY: 'gallery',
  POST: 'post',
  ADULT: 'adult',
  PREMIUM: 'premium'
};

export const GALLERY_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  PREMIUM: 'premium',
  SUBSCRIBER: 'subscriber'
};

// ==============================================
// ==================== CURRENCY ===============
// ==============================================

export const CURRENCY = {
  CODE: 'ARS',
  SYMBOL: '$',
  LOCALE: 'es-AR',
};

// ==============================================
// ================= ERROR MESSAGES =============
// ==============================================

export const ERROR_MESSAGES = {
  GENERAL: 'Ha ocurrido un error. Por favor, intenta nuevamente.',
  CANCEL_ERROR: 'Ocurrió un error al cancelar el pedido. Por favor, intenta nuevamente.',
  PAYMENT_ERROR: 'Error al procesar el pago. Por favor, intenta nuevamente.',
  VALIDATION_ERROR: 'Por favor, verifica los datos ingresados.',
  UNAUTHORIZED: 'No tienes permisos para realizar esta acción.',
  SESSION_EXPIRED: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.',
  CONNECTION_ERROR: 'Error de conexión. Por favor, verifica tu conexión a internet.',
  SERVER_ERROR: 'Error en el servidor. Por favor, intenta más tarde.',
  NOT_FOUND: 'El recurso solicitado no fue encontrado.',
  FORBIDDEN: 'No tienes acceso a este recurso.',
  INVALID_DATA: 'Los datos proporcionados no son válidos.',
  FILE_TOO_LARGE: 'El archivo es demasiado grande.',
  UNSUPPORTED_FILE: 'Tipo de archivo no soportado.',
  UPLOAD_ERROR: 'Error al subir el archivo.',
  DOWNLOAD_ERROR: 'Error al descargar el archivo.',
  TIMEOUT: 'La operación ha excedido el tiempo límite.',
  API_ERROR: 'Error en la comunicación con el servidor.',
  INVALID_CREDENTIALS: 'Credenciales inválidas.',
  ACCOUNT_EXISTS: 'Ya existe una cuenta con este email.',
  WEAK_PASSWORD: 'La contraseña es demasiado débil.',
  INVALID_EMAIL: 'El email no es válido.',
  REQUIRED_FIELD: 'Este campo es requerido.',
  MAX_LENGTH: 'Se ha excedido el límite de caracteres.',
  MIN_LENGTH: 'No cumple con el mínimo de caracteres requeridos.',
  PASSWORDS_NOT_MATCH: 'Las contraseñas no coinciden.',
  INVALID_FORMAT: 'Formato inválido.',
  MEMBERSHIP_REQUIRED: 'Se requiere una membresía premium para esta acción.',
  INSUFFICIENT_FUNDS: 'Saldo insuficiente.',
  TRANSACTION_FAILED: 'La transacción no pudo ser procesada.',
  PAYMENT_CANCELLED: 'El pago ha sido cancelado.',
  PAYMENT_PENDING: 'El pago está pendiente de confirmación.',
  SERVICE_UNAVAILABLE: 'El servicio no está disponible en este momento.',
};

// ==============================================
// ==================== NOTIFICATIONS ==========
// ==============================================

export const NOTIFICATION_TYPES = {
  NEW_SUBSCRIBER: 'new_subscriber',
  SUBSCRIPTION_EXPIRED: 'subscription_expired',
  MEMBERSHIP_EXPIRING: 'membership_expiring',
  NEW_PREMIUM_CONTENT: 'new_premium_content',
  PAYMENT_RECEIVED: 'payment_received',
  SUBSCRIPTION_RENEWAL: 'subscription_renewal'
};

// ==============================================
// ==================== LOADING ================
// ==============================================

export const LOADING_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const LOADING_TEXTS = {
  DEFAULT: 'Cargando...',
  SAVING: 'Guardando...',
  PROCESSING: 'Procesando...',
  UPLOADING: 'Subiendo...',
  DOWNLOADING: 'Descargando...',
  PAYMENT: 'Procesando pago...',
  MEMBERSHIP: 'Actualizando membresía...',
  PROFILE: 'Actualizando perfil...',
  IMAGE: 'Procesando imagen...',
};

// ==============================================
// ==================== UI =======================
// ==============================================

// Constantes de UI
export const UI_CONSTANTS = {
  HEADER: {
    HEIGHT: '64px',
    Z_INDEX: 1000,
  },
  DROPDOWN: {
    Z_INDEX: 1001,
    WIDTH: '280px',
  },
  NOTIFICATIONS: {
    Z_INDEX: 1002,
    WIDTH: '350px',
  },
  MOBILE_MENU: {
    Z_INDEX: 1003,
  },
  MODAL: {
    Z_INDEX: 1004,
  },
  LOADING: {
    Z_INDEX: 1005,
    SIZES: {
      SMALL: 'small',
      MEDIUM: 'medium',
      LARGE: 'large',
    },
    VARIANTS: {
      DEFAULT: 'default',
      FULLSCREEN: 'fullscreen',
      OVERLAY: 'overlay',
    },
  },
};

// ==============================================
// ==================== THEME ====================
// ==============================================

export const THEME = {
  LIGHT: {
    background: 'var(--background-color)',
    cardBackground: 'var(--card-background)',
    text: 'var(--text-color)',
    textSecondary: 'var(--text-secondary)', 
    border: 'var(--border-color)',
    primary: 'var(--primary-color)',
    error: 'var(--error-color)',
    success: 'var(--success-color)',
  },
  DARK: {
    background: 'var(--background-color-dark)',
    cardBackground: 'var(--card-background-dark)', 
    text: 'var(--text-color-dark)',
    textSecondary: 'var(--text-secondary-dark)',
    border: 'var(--border-color-dark)',
    primary: 'var(--primary-color)',
    error: 'var(--error-color)',
    success: 'var(--success-color)',
  },
};

// ==============================================
// ==================== BREAKPOINTS =============
// ==============================================

export const BREAKPOINTS = {
  MOBILE: '480px',
  TABLET: '768px',
  DESKTOP: '1024px',
  LARGE: '1200px',
};

// ==============================================
// ==================== NAVIGATION =============
// ==============================================

export const NAV_ITEMS = [
  { to: '/explore-posts', text: 'Explorar' },
  { to: '/explore-services', text: 'Servicios' },
  { to: '/explore-users', text: 'Usuarios' },
];

// ==============================================
// ==================== ASSETS ===================
// ==============================================

export const DEFAULT_IMAGES = {
  PROFILE: 'https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/non_profile_pic.png?alt=media&token=9ef84cb8-bae5-48cf-aed9-f80311cc2886',
};

// ==============================================
// ==================== ANIMATIONS =============
// ==============================================

export const ANIMATIONS = {
  DURATION: {
    SHORT: 0.2,
    MEDIUM: 0.3,
    LONG: 0.5,
  },
  VARIANTS: {
    FADE_IN: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    },
    SLIDE_DOWN: {
      initial: { opacity: 0, y: -20 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: -20 },
    },
  },
};

// ==============================================
// ==================== UTILITIES ===============
// ==============================================

export const formatCurrency = (amount, currency = 'ARS') => {
  const currencyFormats = {
    ARS: {
      locale: 'es-AR',
      symbol: '$',
      currency: 'ARS',
    },
    USD: {
      locale: 'en-US',
      symbol: 'US$',
      currency: 'USD',
    },
  };

  if (amount === null || amount === undefined || isNaN(amount)) {
    return currencyFormats[currency].symbol + '0,00';
  }
  
  const format = currencyFormats[currency] || currencyFormats.ARS;
  const formattedAmount = new Intl.NumberFormat(format.locale, {
    style: 'currency',
    currency: format.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return format.currency === 'USD' 
    ? `US$${formattedAmount.replace('$', '')}`
    : formattedAmount;
};

export const formatNumber = (num) => {
  if (num === null || num === undefined || isNaN(num)) return '0';
  return new Intl.NumberFormat('es-AR').format(num);
};

