import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';

const ProtectedRoute = ({ children, adminOnly = false }) => {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <LoadingSpinner />;
    }

    // Si no hay usuario, redirigir al login
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    // Solo verificamos el campo verified de Firestore
    if (!user.verified) {
        return <Navigate to="/waiting-verification" replace />;
    }

    // Si la ruta requiere admin y el usuario no lo es, redirigir al home
    if (adminOnly && user.role !== 'admin') {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;