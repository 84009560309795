// src/components/Shared/LoadingSpinner/index.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingSpinner.module.css';
import { UI_CONSTANTS, LOADING_TEXTS } from '../utils/constants';

const { SIZES, VARIANTS } = UI_CONSTANTS.LOADING;

const LoadingSpinner = ({ 
    size = SIZES.MEDIUM, 
    variant = VARIANTS.DEFAULT, 
    text = LOADING_TEXTS.DEFAULT 
}) => {
    const spinnerClasses = [
        styles.spinner,
        styles[size],
        variant === VARIANTS.FULLSCREEN && styles.fullScreen,
        variant === VARIANTS.OVERLAY && styles.overlay
    ].filter(Boolean).join(' ');

    return (
        <div className={spinnerClasses}>
            <div className={styles.spinnerWrapper}>
                <div className={styles.spinnerCircle}></div>
                {text && <p className={styles.spinnerText}>{text}</p>}
            </div>
        </div>
    );
};

LoadingSpinner.propTypes = {
    size: PropTypes.oneOf(Object.values(SIZES)),
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    text: PropTypes.string
};

export default LoadingSpinner;