// src/context/SubscriptionContext.js

import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc, 
  onSnapshot, 
  doc 
} from 'firebase/firestore';
import { toast } from 'react-toastify';

/**
 * Contexto para manejar las suscripciones del usuario.
 */
export const SubscriptionContext = createContext();

/**
 * Hook personalizado para acceder al SubscriptionContext.
 * @returns {object} - Estado y funciones del contexto.
 */
export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription debe ser usado dentro de un SubscriptionProvider');
  }
  return context;
};

/**
 * Provider para el SubscriptionContext.
 * @param {object} props - Props del componente.
 * @param {React.ReactNode} props.children - Componentes hijos.
 * @returns {JSX.Element} - Proveedor del contexto.
 */
export const SubscriptionProvider = ({ children }) => {
  const [subscriptionState, setSubscriptionState] = useState({
    isSubscribed: false,
    subscribedTo: [],
    activeSubscribers: [],
    currentUserSubscriptions: {},
    loading: true,
    error: null
  });

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setSubscriptionState(prev => ({ ...prev, loading: false }));
      return;
    }

    // Referencia a la colección 'subscriptions' filtrada por 'subscriberId' y 'status'
    const subscriptionsQuery = query(
      collection(db, 'subscriptions'),
      where('subscriberId', '==', currentUser.uid),
      where('status', '==', 'active')
    );

    // Escuchar en tiempo real los cambios en las suscripciones activas del usuario
    const unsubscribe = onSnapshot(
      subscriptionsQuery,
      (snapshot) => {
        const subscriptions = {};
        const subscribedTo = [];

        snapshot.forEach(doc => {
          const data = doc.data();
          subscriptions[data.creatorId] = {
            id: doc.id,
            ...data
          };
          subscribedTo.push({
            userId: data.creatorId,
            planId: data.planId
          });
        });

        setSubscriptionState(prev => ({
          ...prev,
          isSubscribed: subscribedTo.length > 0,
          subscribedTo,
          currentUserSubscriptions: subscriptions,
          loading: false,
          error: null
        }));
      },
      (error) => {
        console.error('Error fetching subscriptions:', error);
        setSubscriptionState(prev => ({
          ...prev,
          loading: false,
          error: 'Error al cargar las suscripciones'
        }));
      }
    );

    // Cleanup al desmontar el componente
    return () => unsubscribe();
  }, []);

  /**
   * Función para suscribirse a un creador
   * @param {string} creatorId - ID del creador al que se suscribe
   * @param {string} planId - ID del plan seleccionado
   * @param {object} planDetails - Detalles del plan
   * @returns {string} - ID de la suscripción creada
   */
  const subscribe = useCallback(async (creatorId, planId, planDetails) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        toast.error('Debes iniciar sesión para suscribirte');
        return;
      }

      // Verificar si ya existe una suscripción activa con este creador
      const existingSubscription = subscriptionState.currentUserSubscriptions[creatorId];
      if (existingSubscription && existingSubscription.status === 'active') {
        toast.info('Ya tienes una suscripción activa con este creador');
        return;
      }

      // Datos de la nueva suscripción
      const subscriptionData = {
        creatorId,
        subscriberId: currentUser.uid,
        planId,
        planDetails,
        startDate: new Date(),
        endDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 días
        status: 'active',
        createdAt: new Date()
      };

      // Agregar suscripción a la subcolección 'subscribers' del creador
      const subscribersRef = collection(db, `users/${creatorId}/subscribers`);
      const docRef = await addDoc(subscribersRef, subscriptionData);

      // Agregar suscripción a la colección 'subscriptions'
      await addDoc(collection(db, 'subscriptions'), {
        ...subscriptionData,
        subscriptionId: docRef.id
      });

      // Actualizar el estado local
      setSubscriptionState(prev => ({
        ...prev,
        isSubscribed: true,
        subscribedTo: [...prev.subscribedTo, { userId: creatorId, planId }],
        currentUserSubscriptions: {
          ...prev.currentUserSubscriptions,
          [creatorId]: { ...subscriptionData, id: docRef.id }
        }
      }));

      toast.success('¡Suscripción realizada con éxito!');
      return docRef.id;
    } catch (error) {
      console.error('Error en suscripción:', error);
      toast.error('Error al procesar la suscripción');
      throw error;
    }
  }, [subscriptionState.currentUserSubscriptions]);

  /**
   * Función para desuscribirse de un creador
   * @param {string} creatorId - ID del creador del que se desuscribe
   */
  const unsubscribe = useCallback(async (creatorId) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        toast.error('Debes iniciar sesión');
        return;
      }

      const subscription = subscriptionState.currentUserSubscriptions[creatorId];
      if (!subscription) {
        toast.error('No se encontró la suscripción');
        return;
      }

      // Actualizar el estado de la suscripción a 'cancelled' en la subcolección 'subscribers'
      await updateDoc(doc(db, `users/${creatorId}/subscribers/${subscription.id}`), {
        status: 'cancelled',
        cancelledAt: new Date()
      });

      // Actualizar el estado de la suscripción en la colección 'subscriptions'
      const subscriptionsRef = collection(db, 'subscriptions');
      const q = query(
        subscriptionsRef,
        where('subscriptionId', '==', subscription.id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (docSnapshot) => {
        await updateDoc(docSnapshot.ref, {
          status: 'cancelled',
          cancelledAt: new Date()
        });
      });

      // Actualizar el estado local
      setSubscriptionState(prev => ({
        ...prev,
        subscribedTo: prev.subscribedTo.filter(sub => sub.userId !== creatorId),
        currentUserSubscriptions: {
          ...prev.currentUserSubscriptions,
          [creatorId]: undefined
        }
      }));

      toast.success('Suscripción cancelada exitosamente');
    } catch (error) {
      console.error('Error al cancelar suscripción:', error);
      toast.error('Error al cancelar la suscripción');
      throw error;
    }
  }, [subscriptionState.currentUserSubscriptions]);

  /**
   * Función para verificar si el usuario está suscrito a un creador
   * @param {string} creatorId - ID del creador a verificar
   * @returns {boolean} - True si está suscrito y la suscripción está activa, False de lo contrario
   */
  const checkSubscription = useCallback(async (creatorId) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return false;

      const subscription = subscriptionState.currentUserSubscriptions[creatorId];
      if (!subscription) return false;

      const now = new Date();
      const endDate = new Date(subscription.endDate);
      
      return subscription.status === 'active' && endDate > now;
    } catch (error) {
      console.error('Error al verificar suscripción:', error);
      return false;
    }
  }, [subscriptionState.currentUserSubscriptions]);

  /**
   * Función para obtener información de la suscripción a un creador
   * @param {string} creatorId - ID del creador
   * @returns {object|null} - Datos de la suscripción o null si no existe
   */
  const getSubscriptionInfo = useCallback((creatorId) => {
    return subscriptionState.currentUserSubscriptions[creatorId] || null;
  }, [subscriptionState.currentUserSubscriptions]);

  return (
    <SubscriptionContext.Provider value={{
      ...subscriptionState,
      subscribe,
      unsubscribe,
      checkSubscription,
      getSubscriptionInfo
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
