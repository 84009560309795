// src/components/Header/MobileMenu/MobileMenu.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaUser,
  FaBriefcase,
  FaCompass,
  FaCog,
  FaMoneyBillWave,
  FaCrown,
  FaSignOutAlt
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useMembership } from '../../../hooks/useMembership';
import SearchBar from '../SearchBar/SearchBar';
import styles from './MobileMenu.module.css';

const MobileMenu = ({ 
  user, 
  onClose, 
  onMembershipClick, 
  onLogout 
}) => {
  const { isActive } = useMembership();

  const menuItems = [
    { to: '/explore-posts', icon: <FaCompass />, text: 'Explorar' },
    { to: '/explore-services', icon: <FaBriefcase />, text: 'Servicios' },
    { to: '/explore-users', icon: <FaUser />, text: 'Usuarios' },
  ];

  const userMenuItems = user ? [
    { 
      to: `/profile/${user.uid}`, 
      icon: <FaUser />, 
      text: 'Perfil' 
    },
    { 
      to: '/workbench', 
      icon: <FaBriefcase />, 
      text: 'Mesa de trabajo' 
    },
    {
      to: user.role === 'admin' ? '/admin-dashboard' : '/dashboard',
      icon: <FaCompass />,
      text: user.role === 'admin' ? 'Admin Dashboard' : 'Dashboard'
    },
    { 
      to: '/configuration', 
      icon: <FaCog />, 
      text: 'Configuración' 
    },
    { 
      to: '/monetization',
      icon: <FaMoneyBillWave />,
      text: 'Monetización',
      restricted: !isActive,
      restrictedMessage: 'Requiere membresía Premium'
    },
    { 
      action: onMembershipClick, 
      icon: <FaCrown />, 
      text: 'Membresía' 
    },
    { 
      action: onLogout, 
      icon: <FaSignOutAlt />, 
      text: 'Cerrar sesión', 
      isButton: true 
    }
  ] : [
    { to: '/login', text: 'Iniciar sesión' },
    { to: '/register', text: 'Registrarse' },
  ];

  return (
    <motion.div 
      className={styles.mobileMenu}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.2 }}
    >
      <div className={styles.mobileSearchBar}>
        <SearchBar />
      </div>
      
      <div className={styles.mobileNavigation}>
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            className={styles.mobileMenuItem}
            onClick={onClose}
          >
            {item.icon && 
              <span className={styles.mobileMenuIcon}>
                {item.icon}
              </span>
            }
            <span>{item.text}</span>
          </Link>
        ))}
      </div>

      {user ? (
        <div className={styles.mobileUserMenu}>
          {userMenuItems.map((item, index) => (
            item.to ? (
              <Link
                key={index}
                to={item.to}
                className={`${styles.mobileMenuItem} ${item.restricted ? styles.restricted : ''}`}
                onClick={onClose}
              >
                {item.icon && 
                  <span className={styles.mobileMenuIcon}>
                    {item.icon}
                  </span>
                }
                <span>{item.text}</span>
                {item.restricted && (
                  <span className={styles.restrictedBadge}>
                    {item.restrictedMessage}
                  </span>
                )}
              </Link>
            ) : (
              <button
                key={index}
                className={`${styles.mobileMenuItem} ${item.isButton ? styles.logoutButton : ''}`}
                onClick={() => {
                  if (item.action) item.action();
                  onClose();
                }}
              >
                {item.icon && 
                  <span className={styles.mobileMenuIcon}>
                    {item.icon}
                  </span>
                }
                <span>{item.text}</span>
              </button>
            )
          ))}
        </div>
      ) : (
        <div className={styles.mobileAuthButtons}>
          {userMenuItems.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className={styles.mobileMenuItem}
              onClick={onClose}
            >
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default MobileMenu;