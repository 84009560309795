// src/hooks/useMembership.js

import { useState, useEffect, useCallback, useContext } from 'react';
import { auth } from '../firebaseConfig';
import { useMembershipContext } from '../context/MembershipContext';
import { UserContext } from '../context/UserContext';
import { toast } from 'react-toastify';

export const useMembership = () => {
  const { membershipStatus, setMembershipStatus } = useMembershipContext();
  const { user } = useContext(UserContext);
  const [isActive, setIsActive] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkPremiumStatus = useCallback((userData, membershipData) => {
    // Si es admin, siempre retorna true
    if (userData?.role === 'admin') {
      return true;
    }

    // Si es usuario premium, verificar membresía activa
    if (userData?.role === 'premium') {
      if (membershipData) {
        const endDate = new Date(membershipData.endDate);
        const now = new Date();
        return endDate > now;
      }
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    const updateMembershipState = () => {
      if (!user) {
        setIsActive(false);
        setIsPremium(false);
        setIsAdmin(false);
        setIsLoading(false);
        return;
      }

      // Si es admin, establecer estados correspondientes
      if (user.role === 'admin') {
        setIsActive(true);
        setIsPremium(true);
        setIsAdmin(true);
        setIsLoading(false);
        return;
      }

      const isPremiumUser = checkPremiumStatus(user, membershipStatus);
      setIsActive(isPremiumUser);
      setIsPremium(isPremiumUser);
      setIsAdmin(false);
      setIsLoading(false);
    };

    updateMembershipState();
  }, [user, membershipStatus, checkPremiumStatus]);

  const handlePurchase = useCallback(async (period = 'monthly') => {
    if (!auth.currentUser) {
      toast.error('Debes iniciar sesión para realizar esta acción');
      return;
    }

    if (user?.role === 'admin') {
      toast.info('Los administradores ya tienen acceso completo');
      return;
    }

    if (isPremium) {
      toast.info('Ya tienes una membresía premium activa');
      return;
    }

    setIsLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/membership/create-payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            membershipType: 'premium',
            period,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error al procesar el pago');
      }

      if (data.init_point) {
        window.location.href = data.init_point;
      } else {
        throw new Error('No se recibió el punto de inicio del pago');
      }
    } catch (error) {
      console.error('Error al procesar el pago:', error);
      toast.error('Error al procesar el pago. Intenta de nuevo.');
    } finally {
      setIsLoading(false);
    }
  }, [isPremium, user]);

  const getRemainingTime = useCallback(() => {
    if (user?.role === 'admin') {
      return { days: Infinity };
    }
    
    if (!membershipStatus?.endDate) return { days: 0 };
    
    const endDate = new Date(membershipStatus.endDate);
    const today = new Date();
    const remainingTime = endDate.getTime() - today.getTime();
    const remainingDays = Math.ceil(remainingTime / (1000 * 3600 * 24));
    
    return { days: remainingDays };
  }, [membershipStatus, user]);

  const isExpiringSoon = useCallback(() => {
    if (user?.role === 'admin') return false;
    
    const { days } = getRemainingTime();
    return days <= 7 && days > 0;
  }, [getRemainingTime, user]);

  return {
    isActive,
    isPremium,
    isAdmin,
    membershipStatus,
    getRemainingTime,
    isExpiringSoon,
    handlePurchase,
    isLoading,
    error,
    userRole: user?.role || 'free',
    hasAccess: (feature) => {
      if (user?.role === 'admin') return true;
      if (feature === 'premium' && isPremium) return true;
      return false;
    }
  };
};

export default useMembership;
