// src/contexts/BlockedDomainsContext.js

import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { getDocs, collection } from "firebase/firestore";

export const BlockedDomainsContext = createContext([]);

export const BlockedDomainsProvider = ({ children }) => {
    const [blockedDomains, setBlockedDomains] = useState([]);

    useEffect(() => {
        const fetchBlockedDomains = async () => {
            try {
                const snapshot = await getDocs(collection(db, "blockedDomains"));
                const domains = snapshot.docs
                    .map(doc => {
                        const data = doc.data();
                        if (data && data.domain) {
                            return data.domain.toLowerCase();
                        } else {
                            console.warn(`Documento "${doc.id}" en "blockedDomains" no tiene el campo 'domain'.`);
                            return null;
                        }
                    })
                    .filter(domain => domain !== null); // Filtrar dominios inválidos

                setBlockedDomains(domains);
                console.log("Dominios bloqueados cargados:", domains);
            } catch (error) {
                console.error("Error al obtener dominios bloqueados:", error);
            }
        };

        fetchBlockedDomains();
    }, []);

    return (
        <BlockedDomainsContext.Provider value={blockedDomains}>
            {children}
        </BlockedDomainsContext.Provider>
    );
};
