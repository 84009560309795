// src/components/Header/Notifications/Notifications.jsx
import React, { useEffect, useState, useContext, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { db } from '../../../firebaseConfig';
import { 
  collection, 
  onSnapshot, 
  doc, 
  updateDoc, 
  deleteDoc, 
  query, 
  orderBy, 
  limit, 
  writeBatch 
} from 'firebase/firestore';
import UserContext from '../../../context/UserContext';
import { FaCheck, FaTrash, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Notifications.module.css';
import { toast } from 'react-toastify';

const formatTime = (timestamp) => {
  if (!timestamp) return '';

  const now = new Date();
  const date = timestamp.toDate();
  const diff = now - date;
  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) return 'Ahora';
  if (minutes < 60) return `Hace ${minutes} min`;
  if (hours < 24) return `Hace ${hours}h`;
  if (days < 7) return `Hace ${days}d`;

  return date.toLocaleDateString();
};

const NotificationItem = ({ notification, onMarkRead, onDelete }) => (
  <motion.div 
    className={`${styles.notificationItem} ${notification.read ? '' : styles.unread}`}
    layout
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
  >
    <div className={styles.notificationContent}>
      <div className={styles.notificationTitle}>
        {notification.title}
      </div>
      <div className={styles.notificationMessage}>
        {notification.message}
      </div>
      <div className={styles.notificationTime}>
        {formatTime(notification.timestamp)}
      </div>
    </div>
    <div className={styles.notificationActions}>
      {!notification.read && (
        <button 
          className={`${styles.actionButton} ${styles.readButton}`}
          onClick={() => onMarkRead(notification.id)}
          aria-label="Marcar como leída"
        >
          <FaCheck />
        </button>
      )}
      <button 
        className={`${styles.actionButton} ${styles.deleteButton}`}
        onClick={() => onDelete(notification.id)}
        aria-label="Eliminar notificación"
      >
        <FaTrash />
      </button>
    </div>
  </motion.div>
);

const Notifications = ({ onClose }) => {
  const { user } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, 'users', user.uid, 'Notifications');
    const q = query(
      notificationsRef,
      orderBy('timestamp', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotifications(newNotifications);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const handleMarkRead = useCallback(async (notificationId) => {
    try {
      const notificationRef = doc(db, 'users', user.uid, 'Notifications', notificationId);
      await updateDoc(notificationRef, { 
        read: true,
        readAt: new Date()
      });
      toast.success('Notificación marcada como leída');
    } catch (error) {
      console.error('Error al marcar como leída:', error);
      toast.error('Error al marcar como leída');
    }
  }, [user]);

  const handleDelete = useCallback(async (notificationId) => {
    try {
      await deleteDoc(doc(db, 'users', user.uid, 'Notifications', notificationId));
      toast.success('Notificación eliminada');
    } catch (error) {
      console.error('Error al eliminar:', error);
      toast.error('Error al eliminar');
    }
  }, [user]);

  const handleClearAll = useCallback(async () => {
    if (!window.confirm('¿Eliminar todas las notificaciones?')) return;

    try {
      const batch = writeBatch(db);
      notifications.forEach(notification => {
        const notificationRef = doc(db, 'users', user.uid, 'Notifications', notification.id);
        batch.delete(notificationRef);
      });
      await batch.commit();
      toast.success('Notificaciones eliminadas');
    } catch (error) {
      console.error('Error al eliminar notificaciones:', error);
      toast.error('Error al eliminar notificaciones');
    }
  }, [notifications, user]);

  const handleMarkAllRead = useCallback(async () => {
    try {
      const batch = writeBatch(db);
      const unreadNotifications = notifications.filter(n => !n.read);
      
      unreadNotifications.forEach(notification => {
        const notificationRef = doc(db, 'users', user.uid, 'Notifications', notification.id);
        batch.update(notificationRef, { 
          read: true,
          readAt: new Date()
        });
      });

      await batch.commit();
      toast.success('Todas marcadas como leídas');
    } catch (error) {
      console.error('Error al marcar todas como leídas:', error);
      toast.error('Error al marcar notificaciones');
    }
  }, [notifications, user]);

  const notificationsRoot = document.getElementById('notifications-root');
  if (!notificationsRoot) return null;

  const hasUnreadNotifications = notifications.some(n => !n.read);

  return ReactDOM.createPortal(
    <AnimatePresence>
      <motion.div 
        className={styles.notificationsContainer}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <div className={styles.notificationsHeader}>
          <h2>Notificaciones</h2>
          <div className={styles.headerActions}>
            {notifications.length > 0 && (
              <>
                {hasUnreadNotifications && (
                  <button 
                    className={styles.actionButton} 
                    onClick={handleMarkAllRead}
                    aria-label="Marcar todas como leídas"
                  >
                    <FaCheck /> <span>Marcar leídas</span>
                  </button>
                )}
                <button 
                  className={styles.actionButton} 
                  onClick={handleClearAll}
                  aria-label="Eliminar todas"
                >
                  <FaTrash /> <span>Limpiar</span>
                </button>
              </>
            )}
            <button 
              className={styles.closeButton} 
              onClick={onClose}
              aria-label="Cerrar notificaciones"
            >
              <FaTimes />
            </button>
          </div>
        </div>

        <div className={styles.notificationsList}>
          {loading ? (
            <div className={styles.loadingState}>
              <span className={styles.loader}></span>
              Cargando notificaciones...
            </div>
          ) : notifications.length > 0 ? (
            notifications.map(notification => (
              <NotificationItem 
                key={notification.id}
                notification={notification}
                onMarkRead={handleMarkRead}
                onDelete={handleDelete}
              />
            ))
          ) : (
            <div className={styles.emptyState}>
              No hay notificaciones
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>,
    notificationsRoot
  );
};

export default Notifications;