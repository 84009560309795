// src/App.js

import React, { useContext, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { UserProvider, UserContext } from './context/UserContext';
import { MembershipProvider } from './context/MembershipContext';
import { BlockedDomainsProvider } from './context/BlockedDomainsContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import LoadingScreen from './components/LoadingSpinner/LoadingSpinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Estilos globales
import './Styles/variables.css';
import './Styles/global.css';

// Layout y ProtectedRoute
import Layout from './components/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import ThemeToggle from './components/darkMode/ThemeToggle';
import { ThemeProvider } from './components/darkMode/ThemeProvider';

// Importaciones Core
const Home = React.lazy(() => import('./components/HomeComponents/home'));
const Login = React.lazy(() => import('./components/LoginRegister&Settings/login'));
const Register = React.lazy(() => import('./components/LoginRegister&Settings/register'));
const Profile = React.lazy(() => import('./components/Profile/Profile'));
const Configuration = React.lazy(() => import('./components/User&AdminOptions/Configuration/Configuration'));

// Importaciones de Membresía
const MembershipBenefits = React.lazy(() => import('./components/membership/MembershipBenefits'));
const PremiumDashboard = React.lazy(() => import('./components/membership/PremiumDashboard/PremiumDashboard'));
const MembershipModal = React.lazy(() => import('./components/membership/MembershipModal'));
const UpgradePage = React.lazy(() => import('./components/membership/Upgrade/UpgradePage'));
const MembershipSuccess = React.lazy(() => import('./components/membership/PaymentStatus/Success'));
const MembershipFailure = React.lazy(() => import('./components/membership/PaymentStatus/Failure'));
const MembershipPending = React.lazy(() => import('./components/membership/PaymentStatus/Pending'));
const SuscripcionPage = React.lazy(() => import('./components/membership/SubscriptionPage'));

// Importaciones de PaymentStatus y MercadoPago
const PaymentSuccessComponent = React.lazy(() => import('./components/MercadoPago/PaymentSuccess'));
const PaymentFailureComponent = React.lazy(() => import('./components/MercadoPago/PaymentFailure'));
const PaymentPendingComponent = React.lazy(() => import('./components/MercadoPago/PaymentPending'));
const CallbackPage = React.lazy(() => import('./components/MercadoPago/CallbackPage'));

// Importaciones de Exploración
const ExplorePosts = React.lazy(() => import('./components/explorePosts'));
const ExploreServices = React.lazy(() => import('./components/Explore Services/exploreServices'));
const ExploreUsers = React.lazy(() => import('./components/HomeComponents/ExploreUsers'));
const LearnMore = React.lazy(() => import('./components/HomeComponents/LearnMore'));

// Importaciones de Servicios
const CreatePost = React.lazy(() => import('./components/CreatePost'));
const ServiceRequest = React.lazy(() => import('./components/Services/ServiceRequest'));
const ServiceDetailsUser = React.lazy(() => import('./components/Services/ServiceDetailsUser/ServiceDetailsUser'));
const ServiceDetailsWorker = React.lazy(() => import('./components/Services/ServiceDetailsWorker/ServiceDetailsWorker'));
const InspectPost = React.lazy(() => import('./components/Feed/InspectPost'));

// Importaciones de Dashboard
const UserDashboard = React.lazy(() => import('./components/User&AdminOptions/UserDashboard'));
const AdminDashboard = React.lazy(() => import('./components/User&AdminOptions/AdminDashboard'));
const Workbench = React.lazy(() => import('./components/workbench/Workbench'));

// Importaciones de Autenticación y Perfil
const ForgotPassword = React.lazy(() => import('./components/LoginRegister&Settings/forgotPassword'));
const WaitingVerification = React.lazy(() => import('./components/LoginRegister&Settings/WaitingVerification'));
const Notifications = React.lazy(() => import('./components/Header/Notifications/Notifications'));

// Importaciones Legales
const TermsAndConditions = React.lazy(() => import('./components/HomeComponents/Legal/TermsAndConditions'));
const Privacy = React.lazy(() => import('./components/HomeComponents/Legal/Privacy'));
const Cookies = React.lazy(() => import('./components/HomeComponents/Legal/Cookies'));
const Licenses = React.lazy(() => import('./components/HomeComponents/Legal/Licenses'));
const FAQ = React.lazy(() => import('./components/HomeComponents/Legal/FAQ'));
const Help = React.lazy(() => import('./components/HomeComponents/Legal/Help'));
const Contact = React.lazy(() => import('./components/HomeComponents/Legal/Contact'));

function AppContent() {
  const { loading, user } = useContext(UserContext);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="app-container">
      <Router>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            {/* Rutas de Autenticación */}
            <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
            <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/waiting-verification" 
              element={user ? <WaitingVerification /> : <Navigate to="/register" />} 
            />

            {/* Rutas Principales */}
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/home" element={<Layout><Home /></Layout>} />
            <Route path="/profile/:userId" element={<Layout><Profile /></Layout>} />

            {/* Rutas de MercadoPago */}
            <Route path="/mercadopago/callback" element={<Layout><CallbackPage /></Layout>} />
            <Route path="/mercadopago/callback/success" element={<Layout><PaymentSuccessComponent /></Layout>} />
            <Route path="/mercadopago/callback/failure" element={<Layout><PaymentFailureComponent /></Layout>} />
            <Route path="/mercadopago/callback/pending" element={<Layout><PaymentPendingComponent /></Layout>} />
            
            {/* Rutas de Suscripción */}
            <Route path="/suscripcion" element={<Layout><SuscripcionPage /></Layout>} />

            {/* Rutas de Membresía */}
            <Route path="/membership/*" element={
              <ProtectedRoute>
                <Layout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Routes>
                      <Route path="/" element={<UpgradePage />} />
                      <Route path="benefits" element={<MembershipBenefits />} />
                      <Route path="premium" element={<PremiumDashboard />} />
                      <Route path="upgrade" element={<UpgradePage />} />
                      <Route path="success" element={<MembershipSuccess />} />
                      <Route path="failure" element={<MembershipFailure />} />
                      <Route path="pending" element={<MembershipPending />} />
                    </Routes>
                  </Suspense>
                </Layout>
              </ProtectedRoute>
            } />

            {/* Rutas de Exploración */}
            <Route path="/explore-posts" element={<Layout><ExplorePosts /></Layout>} />
            <Route path="/explore-services" element={<Layout><ExploreServices /></Layout>} />
            <Route path="/explore-users" element={<Layout><ExploreUsers /></Layout>} />
            <Route path="/learn-more" element={<Layout><LearnMore /></Layout>} />

            {/* Rutas Protegidas */}
            <Route path="/configuration" element={
              <ProtectedRoute>
                <Layout><Configuration /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/create-post" element={
              <ProtectedRoute>
                <Layout><CreatePost /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/workbench" element={
              <ProtectedRoute>
                <Layout><Workbench /></Layout>
              </ProtectedRoute>
            } />

            {/* Rutas de Servicios */}
            <Route path="/service-request/:illustratorID/:serviceId" element={
              <ProtectedRoute>
                <Layout><ServiceRequest /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/service-details/:requestId" element={
              <ProtectedRoute>
                <Layout><ServiceDetailsUser /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/service-details/worker/:requestId/:clientId" element={
              <ProtectedRoute>
                <Layout><ServiceDetailsWorker /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/inspect-post/:postId" element={
              <ProtectedRoute>
                <Layout><InspectPost /></Layout>
              </ProtectedRoute>
            } />

            {/* Rutas de Dashboard */}
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Layout><UserDashboard /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/admin-dashboard" element={
              <ProtectedRoute adminOnly>
                <Layout><AdminDashboard /></Layout>
              </ProtectedRoute>
            } />
            <Route path="/notifications" element={
              <ProtectedRoute>
                <Layout><Notifications /></Layout>
              </ProtectedRoute>
            } />

            {/* Rutas Legales */}
            <Route path="/terms" element={<Layout><TermsAndConditions /></Layout>} />
            <Route path="/privacy" element={<Layout><Privacy /></Layout>} />
            <Route path="/cookies" element={<Layout><Cookies /></Layout>} />
            <Route path="/licenses" element={<Layout><Licenses /></Layout>} />
            <Route path="/faq" element={<Layout><FAQ /></Layout>} />
            <Route path="/help" element={<Layout><Help /></Layout>} />
            <Route path="/contact" element={<Layout><Contact /></Layout>} />
          </Routes>
          
          <ThemeToggle />
        </Suspense>
        <ToastContainer 
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Router>
    </div>
  );
}

function App() {
  return (
    <UserProvider>
      <ThemeProvider>
        <HelmetProvider>
          <MembershipProvider>
            <BlockedDomainsProvider>
              <SubscriptionProvider>
                <AppContent />
              </SubscriptionProvider>
            </BlockedDomainsProvider>
          </MembershipProvider>
        </HelmetProvider>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;