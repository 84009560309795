// src/components/darkMode/ThemeToggle.jsx

import React, { useEffect } from 'react';
import { useTheme } from './ThemeProvider';
import styles from './ThemeToggle.module.css';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 't' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        toggleTheme();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [toggleTheme]);

  return (
    <button
      onClick={toggleTheme}
      className={`${styles.themeToggle} ${styles[theme]}`}
      aria-label={`Cambiar a modo ${theme === 'light' ? 'oscuro' : 'claro'}`}
      title={`Cambiar a modo ${theme === 'light' ? 'oscuro' : 'claro'} (Ctrl+T)`}
    >
      {theme === 'light' ? <FaMoon /> : <FaSun />}
    </button>
  );
};

export default ThemeToggle;
