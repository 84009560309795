// src/components/Header/SearchBar.jsx
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, limit, getDocs } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { FaSearch } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import styles from './SearchBar.module.css';

const defaultProfilePic =
  'https://firebasestorage.googleapis.com/v0/b/illustra-6ca8a.appspot.com/o/non_profile_pic.png?alt=media&token=9ef84cb8-bae5-48cf-aed9-f80311cc2886';

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const searchRef = useRef(null);

  // Lógica debounced para la búsqueda
  const performSearch = useCallback(
    debounce(async (queryText) => {
      if (queryText.length === 0) {
        setSearchResults([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const q = query(
          collection(db, 'users'),
          where('username_lower', '>=', queryText.toLowerCase()),
          where('username_lower', '<=', queryText.toLowerCase() + '\uf8ff'),
          limit(5)
        );
        const querySnapshot = await getDocs(q);
        const results = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSearchResults(results);
      } catch (err) {
        console.error('Error searching users:', err);
        setError('Hubo un error al realizar la búsqueda.');
        setSearchResults([]);
        toast.error('Error al realizar la búsqueda. Inténtalo de nuevo.');
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // Manejar cambios de texto en el input
  const handleSearchChange = useCallback(
    (e) => {
      const queryText = e.target.value.trim();
      setSearchQuery(queryText);
      setIsSearching(queryText.length > 0);
      performSearch(queryText);
    },
    [performSearch]
  );

  // Clic en un resultado
  const handleResultClick = useCallback(
    (userId) => {
      navigate(`/profile/${userId}`);
      setSearchQuery('');
      setSearchResults([]);
      setIsSearching(false);
    },
    [navigate]
  );

  // Cerrar dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearching(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles.searchContainer} ref={searchRef}>
      <div
        className={`${styles.searchBar} ${isSearching ? styles.searchBarFocus : ''}`}
        role="search"
      >
        <FaSearch className={styles.searchIcon} aria-hidden="true" />
        <input
          type="text"
          placeholder="Buscar usuario..."
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setIsSearching(searchQuery.length > 0)}
          aria-label="Buscar usuarios"
          autoComplete="off"
        />
      </div>

      <AnimatePresence>
        {isSearching && (
          <motion.div
            className={styles.searchResults}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            role="listbox"
            aria-label="Resultados de búsqueda"
          >
            {isLoading ? (
              <div className={styles.loading}>
                <span>Cargando...</span>
              </div>
            ) : error ? (
              <div className={styles.error}>{error}</div>
            ) : searchResults.length > 0 ? (
              searchResults.map((user) => (
                <motion.div
                  key={user.id}
                  className={styles.searchResultItem}
                  onClick={() => handleResultClick(user.id)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  role="option"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleResultClick(user.id);
                  }}
                >
                  <img
                    src={user.photoURL || defaultProfilePic}
                    alt={`${user.username}'s avatar`}
                    className={styles.searchResultAvatar}
                  />
                  <div className={styles.searchResultInfo}>
                    <span className={styles.searchResultUsername}>
                      {user.username}
                    </span>
                    {user.fullName && (
                      <span className={styles.searchResultFullname}>
                        {user.fullName}
                      </span>
                    )}
                  </div>
                </motion.div>
              ))
            ) : (
              <motion.div
                className={styles.searchNoResults}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {searchQuery.length > 0
                  ? 'No se encontraron resultados.'
                  : 'Comienza a escribir para buscar.'}
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
