// src/components/membership/MembershipBadge.jsx
import React from 'react';
import { FaCrown } from 'react-icons/fa';
import styles from './MembershipBadge.module.css';

const MembershipBadge = ({ size = 'medium' }) => (
  <div className={`${styles.badge} ${styles[size]}`} title="Usuario Premium">
    <FaCrown className={styles.icon} />
  </div>
);

export default MembershipBadge;