// src/components/membership/Membership.jsx

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useMembership } from '../../hooks/useMembership';
import { FaCrown, FaCheck, FaTimes, FaGem } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { auth } from '../../firebaseConfig';
import { MEMBERSHIP_PRICES, CURRENCY } from '../utils/constants';
import styles from './Membership.module.css';

const Membership = ({ onClose }) => {
  const { 
    isActive, 
    isAdmin,
    getRemainingTime,
    isExpiringSoon,
    loading: membershipLoading
  } = useMembership();

  const [loading, setLoading] = useState(false);
  const remaining = getRemainingTime();

  const handlePurchase = async () => {
    if (!auth.currentUser) {
      toast.error('Debes iniciar sesión para realizar esta acción');
      return;
    }

    setLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/membership/create-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          membershipType: 'premium',
          period: 'monthly', // Puedes ajustar esto según el período seleccionado
        }),
      });

      console.log('Respuesta del servidor:', response);
      const data = await response.json();
      console.log('Datos de la respuesta:', data);

      if (!response.ok) {
        throw new Error(data.message || 'Error al procesar el pago');
      }

      if (data.init_point) {
        window.location.href = data.init_point;
      } else {
        throw new Error('No se recibió el punto de inicio del pago');
      }
    } catch (error) {
      console.error('Error detallado:', error);
      toast.error('Error al procesar el pago. Intenta de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  if (membershipLoading) {
    return <div className={styles.loading}>Cargando...</div>;
  }

  return (
    <div className={styles.membershipOverlay} onClick={onClose}>
      <motion.div 
        className={styles.membershipModal}
        onClick={e => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
      >
        <button className={styles.closeButton} onClick={onClose}>
          <FaTimes />
        </button>

        <div className={styles.membershipHeader}>
          <FaCrown className={styles.crownIcon} />
          <h2>Membresía Premium</h2>
          {isAdmin ? (
            <div className={styles.adminStatus}>
              <span>Eres Administrador</span>
            </div>
          ) : isActive ? (
            <div className={styles.membershipStatus}>
              {isExpiringSoon() ? (
                <span className={styles.expiringSoon}>
                  <FaGem /> Expira en {remaining.days} días
                </span>
              ) : (
                <span className={styles.active}>
                  <FaGem /> Activa - {remaining.days} días restantes
                </span>
              )}
            </div>
          ) : null}
        </div>

        <div className={styles.membershipContent}>
          <h3>Beneficios Premium</h3>
          <ul className={styles.benefitsList}>
            <BenefitItem
              title="Monetización de Contenido"
              description="Recibe donaciones y crea contenido exclusivo para tus seguidores"
              isActive={isActive || isAdmin}
            />
            <BenefitItem
              title="Perfil Verificado"
              description="Obtén una insignia de verificación que destaca tu perfil"
              isActive={isActive || isAdmin}
            />
            <BenefitItem
              title="Promoción Prioritaria"
              description="Aparece en las primeras posiciones de búsquedas y exploración"
              isActive={isActive || isAdmin}
            />
            <BenefitItem
              title="Portafolio Extendido"
              description="Aumenta el límite de tu portafolio a 100 imágenes"
              isActive={isActive || isAdmin}
            />
            <BenefitItem
              title="Acceso Anticipado"
              description="Sé el primero en probar las nuevas funcionalidades"
              isActive={isActive || isAdmin}
            />
          </ul>
        </div>

        <div className={styles.membershipFooter}>
          {!isActive && !isAdmin ? (
            <button 
              className={styles.purchaseButton}
              onClick={handlePurchase}
              disabled={loading}
            >
              {loading ? 'Procesando...' : `Obtener Premium - $${MEMBERSHIP_PRICES.premium.monthly.toLocaleString('es-AR')}/mes`}
            </button>
          ) : (
            <>
              {!isAdmin && (
                <button 
                  className={styles.renewButton}
                  onClick={handlePurchase}
                  disabled={loading}
                >
                  {loading ? 'Procesando...' : 'Renovar Membresía'}
                </button>
              )}
              {!isAdmin && (
                <p className={styles.renewInfo}>
                  La renovación extenderá tu membresía por 30 días más
                </p>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

const BenefitItem = ({ title, description, isActive }) => (
  <li className={`${styles.benefitItem} ${isActive ? styles.active : ''}`}>
    <div className={styles.benefitIcon}>
      <FaCheck />
    </div>
    <div className={styles.benefitContent}>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </li>
);

export default Membership;
